import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import GenerateAndSendButton from './GenerateAndSendButton';

function Home() {
    const [annonces, setAnnonces] = useState([]);
    const [editingAnnonce, setEditingAnnonce] = useState(null); 
    const [addAnnonce, setAddAnnonce] = useState(false); 
    const [formValues, setFormValues] = useState({
        reference: '',
        titre: '',
        texte: '',
        prix: '',
        date_saisie: '',
        code_type: '',
        code_postal_reel: '',
        code_postal_internet: '',
        ville_reelle: '',
        ville_internet: '',
        pays: '',
        quartier: '',
        surface: '',
        nb_pieces: '',
        nb_chambres: '',
        nb_salles_de_bain: '',
        surface_terrain: '',
        nb_wc: '',
        garage: '',
        nb_garages: '',
        terrasse: '',
        vente_prix_reel: '',
        contact_a_afficher: '',
        telephone_a_afficher: '',
        url_annonce_sur_site_annonceur: '',
        prix_vendu_net: '',
        photos: ''  // Ajoutez un champ pour les URLs des photos
    });

    useEffect(() => {
        axios.get('/api/annonces')
            .then(response => setAnnonces(response.data))
            .catch(error => console.error('Erreur lors du chargement des annonces:', error));
    }, []);

    const handleDelete = (id) => {
        axios.delete(`/api/annonces/${id}`)
            .then(() => setAnnonces(annonces.filter(annonce => annonce.id !== id)))
            .catch(error => console.error('Erreur lors de la suppression de l\'annonce:', error));
    };

    const handleEdit = (annonce) => {
        setEditingAnnonce(annonce.id);
        setFormValues(annonce);
        setAddAnnonce(false);
    };

    const handleAdd = () => {
        setEditingAnnonce(null);
        resetForm();
        setAddAnnonce(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingAnnonce) { // Si on est en mode édition
            console.log('ID à mettre à jour:', editingAnnonce);  // Vérifiez l'ID ici
            axios.put(`/api/annonces/${editingAnnonce}`, formValues)
                .then(response => {
                    setAnnonces(annonces.map(annonce =>
                        annonce.id === editingAnnonce ? response.data : annonce
                    ));
                    setEditingAnnonce(null);
                    resetForm();
                })
                .catch(error => console.error('Erreur lors de la mise à jour de l\'annonce:', error));
        } else if (addAnnonce) { // Si on est en mode ajout
            axios.post('/api/annonces', formValues)
                .then(response => {
                    setAnnonces([...annonces, response.data]);
                    resetForm();
                })
                .catch(error => console.error('Erreur lors de l\'ajout de l\'annonce:', error));
        }
    };

    const resetForm = () => {
        setFormValues({
            reference: '',
            titre: '',
            texte: '',
            prix: '',
            date_saisie: '',
            code_type: '',
            code_postal_reel: '',
            code_postal_internet: '',
            ville_reelle: '',
            ville_internet: '',
            pays: '',
            quartier: '',
            surface: '',
            nb_pieces: '',
            nb_chambres: '',
            nb_salles_de_bain: '',
            surface_terrain: '',
            nb_wc: '',
            garage: '',
            nb_garages: '',
            terrasse: '',
            vente_prix_reel: '',
            contact_a_afficher: '',
            telephone_a_afficher: '',
            url_annonce_sur_site_annonceur: '',
            prix_vendu_net: '',
            photos: ''  // Réinitialiser l'URL de la photo
        });
        setEditingAnnonce(null);
        setAddAnnonce(false);
    };

    return (
        <div>
            <h2>Liste des Annonces</h2>  
            
            {(editingAnnonce !== null || addAnnonce) && (
    
       <button type="button" style={{ marginBottom: '10px', backgroundColor: 'green',}} onClick={resetForm}>Retour</button>
    
            )}
            
            {(editingAnnonce !== null || addAnnonce) ? (
                <form onSubmit={handleSubmit}>
                    <label>
                        Référence:
                        <input type="text" name="reference" value={formValues.reference} onChange={handleChange} />
                    </label>
                    <label>
                        Titre:
                        <input type="text" name="titre" value={formValues.titre} onChange={handleChange} />
                    </label>
                    <label>
                        Texte:
                        <textarea name="texte" value={formValues.texte} onChange={handleChange} />
                    </label>
                    <label>
                        Prix:
                        <input type="number" name="prix" value={formValues.prix} onChange={handleChange} />
                    </label>
                    {/* Ajout du champ pour l'URL de la photo */}
                    <label>
                        URL de la Photo:
                        <input type="text" name="photos" value={formValues.photos} onChange={handleChange} />
                    </label>
                    <label>
                        Date de Saisie:
                        <input type="date" name="date_saisie" value={formValues.date_saisie} onChange={handleChange} />
                    </label>
                    <label>
                        Code Type:
                        <input type="text" name="code_type" value={formValues.code_type} onChange={handleChange} />
                    </label>
                    <label>
                        Code Postal Réel:
                        <input type="text" name="code_postal_reel" value={formValues.code_postal_reel} onChange={handleChange} />
                    </label>
                    <label>
                        Code Postal Internet:
                        <input type="text" name="code_postal_internet" value={formValues.code_postal_internet} onChange={handleChange} />
                    </label>
                    <label>
                        Ville Réelle:
                        <input type="text" name="ville_reelle" value={formValues.ville_reelle} onChange={handleChange} />
                    </label>
                    <label>
                        Ville Internet:
                        <input type="text" name="ville_internet" value={formValues.ville_internet} onChange={handleChange} />
                    </label>
                    <label>
                        Pays:
                        <input type="text" name="pays" value={formValues.pays} onChange={handleChange} />
                    </label>
                    <label>
                        Quartier:
                        <input type="text" name="quartier" value={formValues.quartier} onChange={handleChange} />
                    </label>
                    <label>
                        Surface:
                        <input type="number" name="surface" value={formValues.surface} onChange={handleChange} />
                    </label>
                    <label>
                        Nombre de Pièces:
                        <input type="number" name="nb_pieces" value={formValues.nb_pieces} onChange={handleChange} />
                    </label>
                    <label>
                        Nombre de Chambres:
                        <input type="number" name="nb_chambres" value={formValues.nb_chambres} onChange={handleChange} />
                    </label>
                    <label>
                        Nombre de Salles de Bain:
                        <input type="number" name="nb_salles_de_bain" value={formValues.nb_salles_de_bain} onChange={handleChange} />
                    </label>
                    <label>
                        Surface du Terrain:
                        <input type="number" name="surface_terrain" value={formValues.surface_terrain} onChange={handleChange} />
                    </label>
                    <label>
                        Nombre de WC:
                        <input type="number" name="nb_wc" value={formValues.nb_wc} onChange={handleChange} />
                    </label>
                    <label>
                        Garage:
                        <input type="text" name="garage" value={formValues.garage} onChange={handleChange} />
                    </label>
                    <label>
                        Nombre de Garages:
                        <input type="number" name="nb_garages" value={formValues.nb_garages} onChange={handleChange} />
                    </label>
                    <label>
                        Terrasse:
                        <input type="text" name="terrasse" value={formValues.terrasse} onChange={handleChange} />
                    </label>
                    <label>
                        Vente Prix Réel:
                        <input type="number" name="vente_prix_reel" value={formValues.vente_prix_reel} onChange={handleChange} />
                    </label>
                    <label>
                        Contact à Afficher:
                        <input type="text" name="contact_a_afficher" value={formValues.contact_a_afficher} onChange={handleChange} />
                    </label>
                    <label>
                        Téléphone à Afficher:
                        <input type="text" name="telephone_a_afficher" value={formValues.telephone_a_afficher} onChange={handleChange} />
                    </label>
                    <label>
                        URL Annonce:
                        <input type="text" name="url_annonce_sur_site_annonceur" value={formValues.url_annonce_sur_site_annonceur} onChange={handleChange} />
                    </label>
                    <label>
                        Prix Vendu Net:
                        <input type="number" name="prix_vendu_net" value={formValues.prix_vendu_net} onChange={handleChange} />
                    </label>
                    <div style={{ marginTop: '10px' }}>
                        <button type="submit" style={{ marginRight: '10px' }}>{editingAnnonce ? 'Enregistrer les modifications' : 'Ajouter l\'annonce'}</button>
                        <button type="button" style={{ marginTop: '10px', backgroundColor: 'red' }} onClick={resetForm}>Annuler</button>
                    </div>
                </form>
            ) : (
                <div>
                    <table>
                <thead>
                    <tr>
                        <th>Référence</th>
                        <th>Titre</th>
                        <th>Image</th>
                        <th>Prix</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {annonces.map(annonce => (
                        <tr key={annonce.id}>
                            <td>{annonce.reference}</td>
                            <td>{annonce.titre}</td>
                            <td>
                                {annonce.photos && annonce.photos.trim() !== '' ? (
                                    <img src={annonce.photos} alt={annonce.reference} style={{ width: '100px', height: 'auto' }} />
                                ) : (
                                    'Pas d\'image'
                                )}
                            </td>
                            <td>{annonce.prix} €</td>
                            <td>
                                        <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEdit(annonce)} />
                                        <FaTrash style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} onClick={() => handleDelete(annonce.id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handleAdd} style={{ marginTop: '20px' }}>Ajouter une Annonce</button>
                </div>
            )}
            {/* Affichage conditionnel du bouton Générer et Envoyer XML */}
            {editingAnnonce === null && addAnnonce === false && (
                <div style={{ marginTop: '20px' }}>
                    <GenerateAndSendButton />
                </div>
            )}
        </div>
    );
}

export default Home;
