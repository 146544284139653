import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Hook pour la navigation

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/login', { username, password });
            if (response.status === 200) {
                setMessage(response.data.message);
                onLogin(); // Notifie le parent (App.js) que l'utilisateur est authentifié
                navigate('/'); // Redirige vers l'accueil
            }
        } catch (error) {
            setMessage('Login failed. Please check your username and password.');
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Nom d'utilisateur:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Mot de passe:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}z
                        required
                    />
                </div>
                <button type="submit">Connexion</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Login;
