import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Home from './components/Home';
import ProfilePage from './components/ProfilePage';
import Login from './components/Login';
import './styles.css';  // Importation du fichier CSS

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    return (
        <Router>
            <div className="App">
                {!isAuthenticated ? (
                    <header className="header">
                        <h1>Connexion Extranet Villas Gaia</h1>
                    </header>
                ) : (
                    <header className="header">
                        <h1>Gestion des Annonces</h1>
                        <nav>
                            <Link to="/">Accueil</Link> | 
                            <Link to="/profile">Profil</Link>
                            <span> | <Link to="/" onClick={() => setIsAuthenticated(false)}>Déconnexion</Link></span>
                        </nav>
                    </header>
                )}
                <div className="container">
                    <Routes>
                        <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
                        <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />} />
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                    </Routes>
                </div>
                <footer>
                    <p>© 2024 Villas Gaia. Tous droits réservés.</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
