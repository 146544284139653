// src/components/ProfilePage.js
import React from 'react';
import Profile from './Profile';

function ProfilePage() {
    return (
        <div>
            <h2>Édition du Profil</h2>
            <Profile />
        </div>
    );
}

export default ProfilePage;
