import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function Profile() {
    const [profil, setProfil] = useState({
        raison_sociale: '',
        adresse_voirie: '',
        adresse_code_postal: '',
        adresse_ville: '',
        telephone: '',
        fax: '',
        email: '',
        web: ''
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null); // État pour gérer la notification de succès
    const navigate = useNavigate(); // Initialisez useNavigate

    useEffect(() => {
        axios.get('/api/profile')
            .then(response => setProfil(response.data))
            .catch(error => setError('Erreur lors du chargement du profil.'));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfil(prevProfil => ({
            ...prevProfil,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('/api/profile', profil)
            .then(response => {
                setProfil(response.data);
                setError(null);
                setSuccess('Profil mis à jour avec succès!'); // Définir le message de succès

                // Rediriger vers la page d'accueil après un délai
                setTimeout(() => {
                    navigate('/');
                }, 2000); // Redirection après 2 secondes
            })
            .catch(error => setError('Erreur lors de la mise à jour du profil.'));
    };

    return (
        <div>
            <h2>Gérer le Profil</h2>

          
    
    <Link to="/"> <button type="button" style={{ marginBottom: '10px', backgroundColor: 'green' }} >Retour</button></Link>

         
            
            <form onSubmit={handleSubmit}>
                <label>
                    Raison Sociale:
                    <input type="text" name="raison_sociale" value={profil.raison_sociale} onChange={handleChange} required />
                </label>
                <label>
                    Voirie:
                    <input type="text" name="adresse_voirie" value={profil.adresse_voirie} onChange={handleChange} required />
                </label>
                <label>
                    Code Postal:
                    <input type="text" name="adresse_code_postal" value={profil.adresse_code_postal} onChange={handleChange} required />
                </label>
                <label>
                    Ville:
                    <input type="text" name="adresse_ville" value={profil.adresse_ville} onChange={handleChange} required />
                </label>
                <label>
                    Téléphone:
                    <input type="text" name="telephone" value={profil.telephone} onChange={handleChange} required />
                </label>
                <label>
                    Fax:
                    <input type="text" name="fax" value={profil.fax} onChange={handleChange} />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" value={profil.email} onChange={handleChange} required />
                </label>
                <label>
                    Site Web:
                    <input type="text" name="web" value={profil.web} onChange={handleChange} required />
                </label>
                <button type="submit">Enregistrer les modifications</button>
                <Link to="/"> <button type="button" style={{ marginTop: '10px', backgroundColor: 'red' }} >Annuler</button></Link>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>} {/* Affichage du message de succès */}
            </form>
        </div>
    );
}


const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    input: {
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
        marginTop: '5px',
        width: '100%',
        boxSizing: 'border-box'
    },
    button: {
        padding: '10px 15px',
        fontSize: '16px',
        borderRadius: '4px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        marginTop: '20px'
    }
};
export default Profile;

