import React from 'react';
import axios from 'axios';

function GenerateAndSendButton() {
    const handleClick = () => {
        axios.post('/api/generate-and-send')
            .then(response => {
                alert('Fichier XML généré, zippé et envoyé avec succès !');
            })
            .catch(error => {
                console.error('Erreur lors de l\'envoi:', error);
                alert('Une erreur est survenue lors de l\'envoi du fichier.');
            });
    };

    return (
        <button onClick={handleClick}>
            Générer et Envoyer XML
        </button>
    );
}

export default GenerateAndSendButton;
